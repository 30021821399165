export default {
  basicViewItem: [{
    prop: 'commission_type',
    label: '',
    Object: 'value'
  }, {
    prop: 'commission_status',
    label: '',
    Object: 'value'
  }, {
    prop: 'commission_amount',
    label: '',
    Object: 'value'
  }, {
    prop: 'host_name',
    label: '',
    Object: 'value'
  }, {
    prop: 'shop_payment',
    label: '',
    Object: 'value'
  }, {
    prop: 'remarks',
    label: '',
    Object: 'value',
    span: 24
  }, {
    prop: 'created_at',
    label: '',
    Object: 'value'
  }, {
    prop: 'updated_at',
    label: '',
    Object: 'value'
  }, {
    prop: 'deleted_at',
    label: '',
    Object: 'value'
  }]
}
